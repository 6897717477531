import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { validateEmail } from '../utils/helpers';
import { send } from 'emailjs-com';

export default function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleName = (e) => {
        if (!e.target.value.length) {
            setErrorMessage(`Name is required.`);
        } else {
            setErrorMessage('');
        }
        setName(e.target.value)
    };

    const handleEmail = (e) => {
        const isValid = validateEmail(e.target.value);
        console.log(isValid);
        if (!isValid) {
            setErrorMessage('Your email is invalid.');
        } else {
            setErrorMessage('');
        }
        setEmail(e.target.value)
    };

    const handleMessage = (e) => {
        setMessage(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!errorMessage) {
            send(
                'durhamappdesigns_email',
                'durhamappdesigns_contact',
                {name, email, message},
                'XDvMuUFfwS409fiG1'
            )
            .then((response) => {
                alert("Message Sent Successfully");
                console.log(response);
            })
            .catch((err) => {
                console.log('Failed', err);
            })
            setName('');
            setEmail('');
            setMessage('');
        }
    };

    return (
        <section className='apart'>
            <Box className='outline'>
                <Typography variant="h3">Contact Us</Typography>
                <FormControl id="contact-form" margin='normal'>
                    <Grid container direction="column">
                        <div className='contact-box'>
                            <TextField id="outlined-basic" label="Name" variant="outlined" value={name} onChange={handleName} name="name" />
                        </div>
                        <div className='contact-box'>
                            <TextField id="outlined-basic" label="Email" variant="outlined" value={email} onChange={handleEmail} name="email" />
                        </div>
                        <div className='contact-box'>
                            <TextField id="multiline-basic" label="Message" variant="standard" value={message} onChange={handleMessage} multiline rows={5} name="message" />
                        </div>
                    </Grid>
                    {errorMessage && (
                        <div>
                            <p className='error-text'>{errorMessage}</p>
                        </div>
                    )}
                    <div className='contact-button'>
                        <Button onClick={handleSubmit} variant="contained" color='primary'>Submit</Button>
                    </div>
                </FormControl>
            </Box>
            <Box className='outline-2'>
                <Typography variant="h3">Our Mailing Address</Typography>
                <Typography variant="h6">Durham App Designs</Typography>
                <Typography variant="h6">4105 Honeysuckle Ct.</Typography>
                <Typography variant="h6">Cincinnati, OH 45241</Typography>
                <br></br>
                <br></br>
                <Typography variant="h3">Donations</Typography>
                <Typography variant="h6">Venmo: @durhamappdesigns</Typography>
            </Box>
        </section>
    )

}
