import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import logo from "../durham-app-designs-2.jpg"

export default function Header() {
  return (
    <Box sx={{ display: 'flex'}}>
      <AppBar component="nav">
        <Toolbar className='App-header'>
          <Box>
            <Link href="#">
              <Button sx={{ color: '#fff',  padding: '7vmin' }}>
                About
              </Button>
            </Link>
            <Link href="#our-apps">
              <Button sx={{ color: '#fff',  padding: '7vmin' }}>
                Our Apps
              </Button>
            </Link>
          </Box>
          <img src={logo} className="App-logo" alt="logo" />
          <Box>        
            <Link href="#team">
              <Button sx={{ color: '#fff', padding: '7vmin' }}>
                Meet the Team
              </Button>
            </Link>
            <Link href="#contact">
              <Button sx={{ color: '#fff', padding: '7vmin' }}>
                Contact
              </Button>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}