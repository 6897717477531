import React from "react";
import logo from '../durham-app-designs-2.jpg';
import Link from '@mui/material/Link';

export default function Footer() {
    return(
      <Link href="#">
        <img src={logo} className="App-logo" alt="logo" />
      </Link>
    )
}