import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import vinny from '../vinny.jpg';
import vincent from '../vincent.jpg';
import vwd from '../vwd.jpg';
import diesel from '../vinny-animated.jpg';
import harambe from '../harambe.jpg';
import Modal from '@mui/material/Modal';

export default function Apps() {
    const [openVince, setOpenVince] = React.useState(false);
    const handleOpen1 = () => setOpenVince(true);
    const handleClose1 = () => setOpenVince(false);
    const [openVincent, setOpenVincent] = React.useState(false);
    const handleOpen2 = () => setOpenVincent(true);
    const handleClose2 = () => setOpenVincent(false);
    const [open22, setOpen22] = React.useState(false);
    const handleOpen3 = () => setOpen22(true);
    const handleClose3 = () => setOpen22(false);
    const [openVinny, setOpenVinny] = React.useState(false);
    const handleOpen4 = () => setOpenVinny(true);
    const handleClose4 = () => setOpenVinny(false);
    const [openHarambe, setOpenHarambe] = React.useState(false);
    const handleOpen5 = () => setOpenHarambe(true);
    const handleClose5 = () => setOpenHarambe(false);
    return (
        <Box>
            <Typography variant="h3">The Team at Durham App Designs</Typography>
            <div className='apart'>
            <Card className='card'>
                <CardMedia
                    component="img"
                    height="250"
                    image={vinny}
                    alt="Vincent Durham"
                    onClick={handleOpen1}
                />
                <CardContent>
                    <Typography variant="h5" component="div">
                        Vince Durham
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                        Owner/Creator
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Vince Durham is the engine behind the team that never stops. The 24-year-old original creator himself!
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button classname="btn-story" size="small" onClick={handleOpen1}>Read More</Button>
                </CardActions>
                <div>
                    <Modal
                        open={openVince}
                        onClose={handleClose1}
                        aria-labelledby="Vince"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className='modal-box'>
                            <Card className="modal-card">
                                <CardMedia
                                    component="img"
                                    className='team-pic'
                                    image={vinny}
                                    alt="Vincent Durham"
                                />
                                <CardContent>
                                    <Typography variant="h5" component="div" id="Vince">
                                        Vince Durham
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div">
                                        Owner/Creator
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Vince is the leading mind for the magical ideas that are turned into reality at Durham App Designs. A former cleaning drone pilot, Vince learned about customer interaction and sharpened his skill set for two years at the tech start-up, Lucid Drone Technologies.
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Song:</strong> Good News - Mac Miller
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Movie:</strong> The Lion King
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Quote:</strong> "Believe in yourself and there will come a day when others will have no choice but to believe with you"
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </Modal>
                </div>
            </Card>
            <Card className="card">
                <CardMedia
                    component="img"
                    height="250"
                    image={vincent}
                    alt="Vincent Durham"
                    onClick={handleOpen2}
                />
                <CardContent>
                    <Typography variant="h5" component="div">
                        Vincent Durham
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                        Marketing Director
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Vincent is the man behind the advertisement. Look at that genius, no task is too tall for this guy!
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={handleOpen2}>Read More</Button>
                </CardActions>
                <div>
                    <Modal
                        open={openVincent}
                        onClose={handleClose2}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className='modal-box'>
                        <Card className="modal-card">
                            <CardMedia
                                component="img"
                                className='team-pic'
                                image={vincent}
                                alt="Vincent Durham"
                            />
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Vincent Durham
                                </Typography>
                                <Typography gutterBottom variant="h6" component="div">
                                    Marketing Director
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Vincent is a young scholar, who earned a degree in Biology from Davidson College in 2020. During his studies, Vincent joined an elite organization full of Davidson's finest young men, Phi Delta Theta. 
                                </Typography>
                                <br></br>
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Favorite Song:</strong> Colder Weather - Zac Brown Band
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Favorite Movie:</strong> The Hangover
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Favorite Vacation Spot:</strong> Minden, WV
                                </Typography>
                            </CardContent>
                        </Card>
                        </Box>
                    </Modal>
                </div>
            </Card>
            <Card className="card">
                <CardMedia
                    component="img"
                    height="250"
                    image={vwd}
                    alt="Vincent Durham"
                    onClick={handleOpen3}
                />
                <CardContent>
                    <Typography variant="h5" component="div">
                        22
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                        HR Representative
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        22 keeps the whole team happy. A former athlete himself, he knows a thing or two about keeping a squad together.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={handleOpen3}>Read More</Button>
                </CardActions>
                <div>
                    <Modal
                        open={open22}
                        onClose={handleClose3}
                        aria-labelledby="Vince"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className='modal-box'>
                            <Card className="modal-card">
                                <CardMedia
                                    component="img"
                                    className='team-pic'
                                    image={vwd}
                                    alt="Vincent Durham"
                                />
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        22
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div">
                                        HR Representative
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        22 was a member of the Davidson football program, where he played heavy slot. Although he wasn't the most athletic kid on the team, he played an integral role as a 'Social' team captain, maintaining happiness and good vibes across the board between players and coaches of all ages. 
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body2" color="text.secondary">
                                    <strong>Favorite Song:</strong> Mo Bamba - Sheck Wes
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Show:</strong> Blue Mountain State 
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Football Player:</strong> Joe Burrow
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </Modal>
                </div>
            </Card>
            <Card className="card">
                <CardMedia
                    component="img"
                    height="250"
                    image={diesel}
                    alt="Vincent Durham"
                    onClick={handleOpen4}
                />
                <CardContent>
                    <Typography variant="h5" component="div">
                        Vinny D
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                        UI Design
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Vinny D is the brains behind the coding for user interaction and experience. Young man just tries his best everyday.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={handleOpen4}>Read More</Button>
                </CardActions>
                <div>
                    <Modal
                        open={openVinny}
                        onClose={handleClose4}
                        aria-labelledby="Vince"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className='modal-box'>
                            <Card className="modal-card">
                                <CardMedia
                                    component="img"
                                    className='team-pic'
                                    image={diesel}
                                    alt="Vincent Durham"
                                />
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        Vinny D
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div">
                                        UI Design
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Vinny D is the prodigy who codes and designs each of our apps. He learned app coding through a UNC-Charlotte Full-Stack coding bootcamp and is now taking classes to pursue a graduate degree in Computer Science at the University of Dayton. 
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Song:</strong> Overthinker - INZO
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Movie:</strong> The Social Network
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Character:</strong> Jack Sparrow 
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </Modal>
                </div>
            </Card>
            <Card className="card">
                <CardMedia
                    component="img"
                    height="250"
                    image={harambe}
                    alt="Vincent Durham"
                    onClick={handleOpen5}
                />
                <CardContent id='contact'>
                    <Typography variant="h5" component="div">
                        Harambe
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                        Heart & Soul
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Harambe lives on as the heart and soul behind this team. He never got the chance to code, so we do it for him!
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={handleOpen5}>Read More</Button>
                </CardActions>
                <div>
                    <Modal
                        open={openHarambe}
                        onClose={handleClose5}
                        aria-labelledby="Vince"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className='modal-box'>
                            <Card className="modal-card">
                                <CardMedia
                                    component="img"
                                    className='team-pic'
                                    image={harambe}
                                    alt="Vincent Durham"
                                />
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        Harambe
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div">
                                        Heart & Soul
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Harambe was a Western Lowland Gorilla born in a zoo in Texas, before being transferred to the Cincinnati Zoo in 2014. On May 28th, 2016, Harambe's life was taken at just 17 years old in a tragic accident at the zoo. If he was still around, we can only imagine he would love to be on our team!
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Song:</strong> With Arms Wide Open - Creed
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Movie:</strong> Madagascar: Escape 2 Africa
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Favorite Quote:</strong> "You don't think anyone would really jump into the gorilla exhibit, do you?"
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </Modal>
                </div>
            </Card>
            </div>
        </Box>
        
    )
}