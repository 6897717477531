import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

export default function Header() {
    return (
        <Box className='box'>
            <ThemeProvider theme={theme}>
                <Typography variant="h3">About Durham App Designs</Typography>
                <Typography id='our-apps' variant="h6">Durham App Designs LLC is a small company that creates applications and websites with real-world uses. Established in August of 2022, our hats have always been hung on designing simple and easy-to-use applications for people of all ages to enjoy. 
                We hope you enjoy our apps and please feel free to reach out with our contact form if you have any ideas or improvements for applications.</Typography>
            </ThemeProvider>
        </Box>

    )
};