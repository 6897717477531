import Header from './Header';
import About from './About';
import Apps from './Apps';
import Team from './Team';
import Contact from './Contact';
import Footer from './Footer';

export default function Home() {
    return(
        <div className="App">
          <Header></Header>
          <About></About>
          <Apps></Apps>
          <Team></Team>
          <Contact></Contact>
          <Footer></Footer>
        </div>
    )
}